<template>
  <div class="list row">
    <h2>Dokumenta</h2>
    <div class="actions d-flex flex-row-reverse">
      <button
        type="button"
        class="btn btn-primary bi bi-arrow-clockwise"
        @click="refreshData"
      />
      <button
        type="button"
        class="btn btn-success bi bi-plus"
        @click="addData"
      />
    </div>
    <data-table
      :url="`files/${parent}/${pid}`"
      :columns="columns"
      :templates="templates"
      :headings="headers"
      :actions="actions"
      ref="datatable"
      @yes="deleteData"
      name="files"
    >
    </data-table>
  </div>
</template>

<script>
import FilesDataService from "../../services/files.service";
import DataTable from "../widgets/DataTable.vue";
import FilesForm from "./FilesForm.vue";

export default {
  name: "files",
  props: ["parent", "pid"],
  components: {
    DataTable,
  },
  data() {
    return {
      columns: ["name", "comment", "edit"],
      headers: {
        name: "Naziv",
        comment: "Komentar",
        edit: "",
      },
      templates: {},
      actions: {
        // edit: { commp: FilesForm },
        btn1: {
          color: "warning",
          icon: "bi-download",
          action: (data) => this.downloadFile(data),
        },
        delete: {
          deleteData: this.deleteData,
          color: "danger",
          icon: "bi-trash",
        },
        // print: null,
      },
      currentIndex: -1,
      title: "",
    };
  },
  methods: {
    refreshData() {
      this.$refs.datatable.onRefresh();
    },
    addData() {
      this.$store.commit("app/showModal", {
        modal: FilesForm,
        params: {
          data: { folder: this.parent, pid: this.pid },
          title: "Dodaj novi",
        },
      });
    },
    downloadFile(data) {
      console.log({ data });
      FilesDataService.download(data.name, this.parent, this.pid);
    },
    deleteData(id) {
      FilesDataService.delete(id)
        .then((response) => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: response.data.message,
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          console.log(e);
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>

<style scoped>
.list {
  text-align: left;
  margin: auto;
}
</style>
