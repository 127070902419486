import http from "../utils/http-common";
import axios from "axios";

class FilesDataService {
  getAll() {
    return http.get("/files/reservation");
  }

  get(id) {
    return http.get(`/files/${id}`);
  }

  create(data) {
    return http.post("/files", data);
  }

  upload(file, subdir, id) {
    const headers = { 'Content-Type': 'multipart/form-data' };
    let formData = new FormData();
    formData.append('uploadFile', file);
    return axios.post(process.env.VUE_APP_BE_URL + `api/files/upload/${subdir}/${id}`, formData, { withCredentials: true, headers });
  }

  download(file, subdir, id) {
    const link = document.createElement("a");
    link.href = process.env.VUE_APP_BE_URL + `api/files/download/${subdir}/${id}/${file}`;
    link.download = file;
    link.click();
  }

  update(id, data) {
    return http.put(`/files/${id}`, data);
  }

  delete(id) {
    return http.delete(`/files/${id}`);
  }
}

export default new FilesDataService();