<template>
  <div class="container d-flex justify-content-start">
    <div class="row">
      <div class="col">
        <div
          class="file-drop-area"
          @dragover="dragover"
          @dragleave="dragleave"
          @drop="drop"
        >
          <span class="choose-file-button">Izaberi dokument</span>
          <span class="file-message">ili prevuci fajl ovde</span>
          <input
            class="file-input"
            type="file"
            name="fields[assetsFieldHandle][]"
            id="assetsFieldHandle"
            @change="onChange"
            ref="file"
            accept=".pdf,.jpg,.jpeg,.png,.doc,.docx,.xls,.xlsx"
          />
        </div>
      </div>
      <ul class="mt-4 list-unstyled" v-if="this.filelist.length" v-cloak>
        <li class="text-sm mb-1" v-for="file in filelist" :key="file.name">
          <span class="pe-2">{{ file.name }}</span>
          <button
            class="btn btn-danger btn-sm"
            type="button"
            @click="remove(filelist.indexOf(file))"
            title="Remove file"
          >
            <i class="bi bi-x-lg"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: "file-uploader",
  delimiters: ["${", "}"], // Avoid Twig conflicts
  data() {
    return { filelist: [] }; // Store our uploaded files
  },
  methods: {
    onChange(e) {
      this.filelist = [...this.$refs.file.files];
      console.log({ f: e });
      console.log(this.$refs.file.files[0]);
      this.$emit("selected", this.$refs.file.files[0]);
    },
    remove(i) {
      this.filelist.splice(i, 1);
    },
    dragover(event) {
      event.preventDefault();
      // Add some visual fluff to show the user can drop its files
      if (!event.currentTarget.classList.contains("bg-secundary")) {
        event.currentTarget.classList.remove("bg-secundary");
        event.currentTarget.classList.add("bg-light");
      }
    },
    dragleave(event) {
      // Clean up
      event.currentTarget.classList.add("bg-secundary");
      event.currentTarget.classList.remove("bg-light");
    },
    drop(event) {
      event.preventDefault();
      this.$refs.file.files = event.dataTransfer.files;
      this.onChange(); // Trigger the onChange event manually
      // Clean up
      event.currentTarget.classList.add("bg-secundary");
      event.currentTarget.classList.remove("bg-light");
    },
  },
  computed: {},
  mounted() {},
};
</script>

<style scoped>
.file-drop-area {
  position: relative;
  display: flex;
  align-items: center;
  width: 450px;
  /* height: 250px; */
  max-width: 100%;
  padding: 25px;
  border: 1px dashed black;
  border-radius: 3px;
  transition: 0.2s;
}

.choose-file-button {
  flex-shrink: 0;
  background-color: rgba(255, 255, 255, 0.04);
  border: 1px solid gray;
  border-radius: 3px;
  padding: 8px 15px;
  margin-right: 10px;
  font-size: 12px;
  text-transform: uppercase;
}

.file-message {
  font-size: small;
  font-weight: 300;
  line-height: 1.4;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.file-input {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  cursor: pointer;
  opacity: 0;
}

.mt-100 {
  margin-top: 100px;
}
</style>