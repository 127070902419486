<template>
  <file-uploader @selected="fileSelected" />
  <data-form
    :data="data"
    :formFields="formFields"
    @save="saveData"
    @cancel="cancel"
  />
</template>

<script>
import DataForm from "../widgets/DataForm.vue";
import FilesDataService from "../../services/files.service";
import FileUploader from "../widgets/FileUploader.vue";

export default {
  name: "file-form",
  props: ["data"],
  components: {
    DataForm,
    FileUploader,
  },
  data() {
    return {
      message: "",
      file: null,
      folder: "",
    };
  },
  computed: {
    formFields() {
      return {
        id: { type: "hidden" },
        // name: { label: "Naziv", type: "text" },
        comment: { label: "Komentar", type: "textarray" },
      };
    },
  },
  methods: {
    fileSelected(file) {
      this.file = file;
    },
    saveData(newData) {
      if (newData && newData.id) {
        // this.update(newData);
      } else {
        // newData["name"] = this.file.name;
        newData = {
          ...newData,
          name: this.file.name,
          parent: this.data.folder,
          pid: this.data.pid,
        };
        console.log({ newData });
        this.create(newData);
      }
    },
    submitFile(id) {
      console.log({ file: this.file, subfolder: this.data.folder, id });
      return FilesDataService.upload(
        this.file,
        this.data.folder,
        this.data.pid
      );
    },
    cancel() {
      this.$store.commit("app/hideModal");
    },
    update(newData) {
      FilesDataService.update(newData.id, newData)
        .then(() => {
          this.$store.commit("app/showAlert", {
            type: "success",
            message: "Podaci su promenjeni!",
          });
          this.$store.commit("app/hideModal");
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
    create(newData) {
      FilesDataService.create(newData)
        .then((data) => {
          console.log({ data });
          this.submitFile(data.data.id)
            .then(() => {
              this.$store.commit("app/showAlert", {
                type: "success",
                message: "Fajl je kreiran!",
              });
              this.$store.commit("app/hideModal");
            })
            .catch((e) => {
              this.$store.commit("app/showAlert", {
                type: "danger",
                message: e,
              });
            });
        })
        .catch((e) => {
          this.$store.commit("app/showAlert", {
            type: "danger",
            message: e,
          });
        });
    },
  },
};
</script>